.topbar {
    width: 100%;
    height: 50px;
    margin-bottom: 40px;
}
  
.topbarWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-bar-title {
    cursor: pointer;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #555555;
}
  
  .top-bar-logo {
    object-fit: contain;
    width: 100px;
      height: 100%;
  }
  
  .topRight{
      display: flex;
      align-items: center;
  }
  
  .topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
  }
  
  .topIconBadge{
      width: 15px;
      height: 15px;
      position: absolute;
      top: -5px;
      right: 0px;
      background-color: red;
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
  }
  
  .topAvatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
  }