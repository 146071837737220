@media print {
    @page {
        size: 3.2cm 5.7cm;
        page-break-after: avoid;
    }

    #selectionToPrint {
        visibility: visible;
        position: absolute;
        bottom: 32%;
        left: -5%;
        display: flex;
        flex-direction: column;
        page-break-after: avoid;
        transform: rotate(90deg);
    }

    .flexContainer {
        display: flex;
        justify-content: space-between;
    }

    .columnContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .itemName {
        font-size: 12px;
    }

    .itemPrice {
        font-size: 16px;
    }

    .itemPrice span {
        font-size: 10px;
    }

    .itemReference {
        font-size: 10px;
    }

    .itemDescription {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 7px;
    }

    .itemDescription span {
        text-decoration: underline;
        font-size: 8px;
    }

    .containerBarcode {
        align-self: center;
    }

    #rowButtons {
        visibility: hidden;
        display: none;
    }

}