@import url('https://fonts.googleapis.com/css2?family=Staatliches&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
    padding: 0;
    box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

#root {
  font-family: Staatliches, sans-serif;
}

.container {
  display: flex;
  height: 100vh;
}


.link {
  text-decoration: none;
  color: inherit;
}