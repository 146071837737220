.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 400px;
    margin-bottom: 40px;
}

.login{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 50px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}