.newProduct {
    flex: 4;
  }

.search-products-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.search-products-container > * {
    flex: 0.5;
}

.upperCase {
    text-transform: uppercase;
}
  
  .addProductItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    /*width: 100%;*/
  }

  .clientInfos {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 2rem;
    justify-content: center;
  }
  
  .addProductItem > label {
    color: gray;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .addProductItem > button {
    padding: 10px;
  }
  
  .addProductItem > input {
    padding: 10px;
  }
  
  .addProductItem > select {
    padding: 10px;
  }
  
  .addProductButton {
    margin-top: 10px;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

.invoiceTable {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  border: 1px solid black;
}

.invoiceTable td {
  border: 1px solid black;
}

.section {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.addProductTitle {
  margin-top: 0;
}