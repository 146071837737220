.item-card-content {
    font-family: Roboto, sans-serif;
}

@media print {
    @page {
        size: landscape;
        margin: 0;
        padding: 0;
    }

    * {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .item-card > div {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .container {
        visibility: hidden;
    }

    #item-price-label-print {
        box-sizing: border-box;
        width: 8.9cm;
        height: 5.1cm;
        visibility: visible !important;
        margin: 0;
        padding: 0;
        border: 1px solid black;
        page-break-inside: avoid;
        page-break-after: always;
        zoom: 100%;
    }

    #item-price-label-print > * {
        margin: 0;
        padding: 0 0 0 5px;
    }

    .item-card-content > p {
        margin: 0;
        padding: 0;
    }

    #item-price-label-hide {
        visibility: hidden !important;
    }

    .item-card-name,
    .item-card-reference,
    .item-card-description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


}
