.sidebar {
  height: 100%;
  background: #EFEFFF;
  position: sticky;
  font-family: "Staatliches", sans-serif;
  width: 300px;
  transition: all 0.4s ease;
}

.sidebar.collapsed {
  width: 73px;
  overflow: hidden;
}

.sidebar.collapsed .sidebarMenuContainer {
  align-items: center;
}

.sidebar.collapsed .sidebarLogout {
  justify-content: center;
}

.sidebar.collapsed .top-bar-title,
.sidebar.collapsed .top-bar-logo,
.sidebar.collapsed .sidebarListItem > span {
    display: none;
}

.sidebar.collapsed .sidebarList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sidebar.collapsed .sidebarLogout {
  gap: 230px;
}

.sidebar.collapsed .sidebarIconLogout {
    transform: rotateZ(180deg);
}
  
  .sidebarWrapper {
    padding: 20px;
    color: #555;
    height: 80%;
  }

  .sidebarMenuContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .sidebarMenu {
    margin-bottom: 20px;
  }
  
  .sidebarTitle {
    font-size: 13px;
    color: rgb(187, 186, 186);
  }
  
  .sidebarList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebarListItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 30px;
    gap: 1rem;
  }

  .sidebarListItem > span {
    border-bottom: 1px solid transparent;
    white-space: nowrap;
    transition: all 0.4s ease;
  }

  .sidebarLogout {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .sidebarIconLogout {
    transform: rotateZ(0deg);
    cursor: pointer;
  }

  .sidebarListItem.active > span,
  .sidebarListItem:hover > span {
    border-bottom: 1px solid #555;
  }

  .sidebarIcon {
    width: 20px !important;
  }

  .sidebarIcon.active {
    color: #E4221D;
  }