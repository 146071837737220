.home {
    flex: 4;
    padding: 40px;
    gap: 80px;
    display: flex;
    flex-direction: column;
}

.homeWidgets {
    display: flex;
    margin: 20px;
}

.homeTitle {
    font-family: 'Staatliches', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #555555;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.homeSubtitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #555555;
}

.homeLinksContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, auto));
    gap: 40px;
}

.homeLink {
    background: #E4221D;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 24px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    transition: all 0.4s ease;
}

.homeLink:hover {
    background: #ffffff;
    color: #E4221D;
}

.homeLinkIcons {
    display: flex;
    gap: 8px;
}

.homeLinkIcons > svg {
    width: 19px;
    height: 19px;
}

.homeLink > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
}
