.product {
    flex: 4;
    padding: 20px;
  }
  
  .productTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .productAddButton {
    border: none;
    padding: 10px;
    margin: 10px 0;
    background-color: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .editProductContainer {
    display: flex;
    /*align-items: center;*/
    /*justify-content: space-between;*/
  }
  
  .productTop {
    display: flex;
    flex: 1;
  }
  
  .productTopLeft,
  .productTopRight {
    flex: 1;
  }
  
  .productTopRight {
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .productInfoImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .productInfoTop {
    display: flex;
    align-items: center;
  }
  
  .productName {
    font-size: 28px;
    font-weight: 600;
  }
  
  .productInfoBottom {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
  }
  
  .productInfoItem {
    display: flex;
    gap: 20px;
  }
  
  /*.productInfoValue {*/
  /*  font-weight: 300;*/
  /*}*/
  
  .productBottom {
    flex: 1;
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .productForm {
    display: flex;
    flex-direction: column;
  }
  
  .productFormLeft {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  .productFormLeft > .addProductItem {
    width: 100%;
  }
  
  .productFormLeft > label {
    margin-bottom: 10px;
    color: gray;
  }
  
  .productFormLeft > input {
    margin-bottom: 10px;
    border: none;
    padding: 5px;
    border-bottom: 1px solid gray;
  }
  
  .productFormLeft >select{
    margin-bottom: 10px;
  }
  
  .productUploadImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .productFormRight{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .productUpload{
    display: flex;
    align-items: center;
  }
  
  .productButton{
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: darkblue;
    color:white;
    font-weight: 600;
    cursor: pointer;
  }

  .productForm > button {
    background: #E4221D;
    border-width: 0;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 24px 48px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
  }

  .productInfoKey,
  .productInfoValue {
    font-size: 18px;
  }