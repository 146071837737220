.productList{
    flex: 4;
}

.productListItem{
    display: flex;
    align-items: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListView{
    border: none;
    border-radius: 10px;
    padding: 2px 8px;
    background-color: #3b7fb0;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListDelete{
    color: #ff0000;
    cursor: pointer;
}

.productListDeleteComment{
    color: #ff0000;
    cursor: pointer;
}

.productListEditComment{
    color: #2424e3;
    cursor: pointer;
}