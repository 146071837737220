.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 40px;
    gap: 40px;
  }

.above-table {
    display: flex;
    justify-content: space-between;
}

.above-table .title-search-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttonsPanel {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.above-table .title {
    font-family: 'Staatliches', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #555555;
    text-transform: uppercase;
}

.above-table .search {

}

.above-table .invoice-number > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14994px;
    color: rgba(0, 0, 0, 0.87);
}

.above-table .invoice-number > span:nth-child(1) {
    font-size: 14px;
}

.above-table .invoice-number > span:nth-child(2) {
    font-weight: 700;
}

.above-table .create-button,
.above-table .create-button-fixed
{
    background: #E4221D;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 16px 24px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
}

.above-table .create-button > span,
.above-table .create-button-fixed > span
{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
}

.above-table .create-button:hover,
.above-table .create-button-fixed:hover
{
    background: #ffffff;
    color: #E4221D;
}

.above-table .create-button-icons {
    display: flex;
    gap: 8px;
}

.title-refresh-container {
    display: flex;
    gap: 1rem;
    align-items: center
}

.addProductItem .create-button {
    background: #E4221D;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 16px 24px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
}

.addProductItem .create-button > span, .addProductItem .create-button-fixed  > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
}

.divider {
    border-top: 1px solid #D4D4D4;
    margin: 32px 0;
}

.create-button.disabled {
    cursor: default;
    background: #D4D4D4;
}

.refresh-button {
    background: #1d6de4;
    border-width: 0;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 18px 10px;
    gap: 8px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
}

.refresh-button > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase ;
}

.preview-button {
    background: #1d8ae4 !important;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 16px 24px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
    margin-bottom: 5px;
}

.create-button, .create-button-fixed {
    background: #E4221D;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 16px 24px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
}

.create-button-fixed {
    width: 300px;
}

.create-button-icons > svg {
    width: 19px;
    height: 19px;
}

.preview-button > span,
.create-button > span,  .create-button-fixed  > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase ;
}

.create-button-outline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border: 1px solid #E4221D;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    cursor: pointer;
    width: 42px;
    height: 40px;
    margin-left: auto;
    transition: all 0.4s ease;
    background: white;
}

.add-button-icons > svg {
    color: #E4221D;
    transition: all 0.4s ease;
}

.create-button-outline:hover .add-button-icons > svg {
    color: #ffffff;
}

.create-button-outline .create-button-icons > svg {
    width: 19px;
    height: 19px;
    color: #E4221D;
    transition: all 0.4s ease;
}

.create-button-outline:hover .create-button-icons > svg {
    color: #ffffff;
}

.create-button-outline > span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
}

.create-button-outline:hover {
    background: #E4221D;
    color: #ffffff;
}
  
  .userListUser {
    display: flex;
    align-items: center;
  }
  
  .userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

  .userListInvoices {
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #3b72b0;
      color: white;
      cursor: pointer;
      margin-right: 20px;
  }

.userListCreditNotes {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #b05a3b;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
  
  .userListEdit{
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #3bb077;
      color: white;
      cursor: pointer;
      margin-right: 20px;
  }
  
  .userListDelete{
      color: red;
      cursor: pointer;
  }

  .download-print-buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
  }