.invoiceList{
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 4;
}

.invoiceListItem{
    display: flex;
    align-items: center;
}
.button-options-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.create-button:hover {
    background: #ffffff;
    color: #E4221D;
}

.invoiceListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.invoiceListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.invoiceListDelete{
    color: red;
    cursor: pointer;
}

.invoiceAddButton {
    border: none;
    padding: 10px;
    margin: 10px 0;
    background-color: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.arrow-back-button {
    border: none;
    padding: 10px;
    margin: 10px 0;
    background-color: #939393;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}