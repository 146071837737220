.newProduct {
    flex: 4;
  }

  .addProductForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  
  .addProductItem > label {
    color: gray;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .addProductItem > input {
    padding: 10px;
  }
  
  .addProductItem > select {
    padding: 10px;
  }
  
  .addProductButton {
    margin-top: 10px;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .addProductForm > button.create-button {
    background: #E4221D;
    border-width: 0;
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 24px 48px;
    gap: 16px;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    max-height: 19px;
    cursor: pointer;
  }